var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('pickupRequest.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('pickupRequest.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-loading-banner',{attrs:{"loading":_vm.warn_request_confirm}},[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('div',{staticClass:"tw-flex tw-space-x-3"},[(_vm.$can('create-pickup-requests'))?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addNew}},[_vm._v(" "+_vm._s(_vm.$t('addNew'))+" ")]):_vm._e(),_c('a-date-picker',{attrs:{"format":"DD-MM-YYYY","valueFormat":"DD-MM-YYYY","allowClear":false},on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.dateRequest),callback:function ($$v) {_vm.dateRequest=$$v},expression:"dateRequest"}}),_c('a-select',{staticStyle:{"width":"200px"},attrs:{"placeholder":"All","allowClear":true,"options":[
                                { value: 1, label: 'Requesting' },
                                { value: 2, label: 'Confirmed' },
                                { value: 3, label: 'Completed' },
                                { value: 4, label: 'Cancelled' }
                            ]},on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('a-select',{staticStyle:{"width":"200px"},attrs:{"placeholder":"All","allowClear":true,"options":[
                                { value: 1, label: 'បញ្ញើរហ័ស	' },
                                { value: 2, label: 'បញ្ញើធម្មតា	' }
                            ]},on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.types),callback:function ($$v) {_vm.types=$$v},expression:"types"}})],1),_c('a-tooltip',{attrs:{"title":_vm.$t('pickupRequest.search')}},[_c('a-input-search',{staticStyle:{"width":"200px"},attrs:{"placeholder":_vm.$t('pickupRequest.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]),_c('div',{staticClass:"tw-overflow-x-scroll"},[_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var record = ref.record;
return [_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.request_time)+" ")]),_c('td',[_vm._v(_vm._s(record.shop_name))]),_c('td',[_vm._v(_vm._s(record.phone_number))]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.destination)+" ")]),_c('td',[_vm._v(_vm._s(record.package_type_kh))]),_c('td',[_vm._v(_vm._s(record.service_type_name_kh))]),_c('td',[_vm._v(" "+_vm._s(record.driver ? record.driver.driver_name : '')+" ")]),_c('td',{staticClass:"tw-whitespace-nowrap"},[(record.driver)?[_c('p',{staticClass:"tw-mb-0"},[_c('span',[_vm._v(_vm._s(record.driver.phone1 + ' / ' + record.driver.phone2))])])]:_vm._e()],2),_c('td',[_vm._v(" "+_vm._s(record.assign_driver)+" ")]),_c('td',{staticClass:"tw-text-center"},[_vm._v(" "+_vm._s(record.booking_pickups_count)+" ")]),_c('td',[_vm._v(_vm._s(record.pickup_status))]),_c('td',[_vm._v(" "+_vm._s(record.cancel_reason ? record.cancel_reason : '')+" ")]),_c('td',{staticClass:"tw-text-left tw-space-x-2 tw-whitespace-nowrap"},[_c('a-tooltip',{attrs:{"placement":"topLeft"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("Assign driver")])]},proxy:true}],null,true)},[(record.pickup_status_id == 1)?_c('a',{staticClass:"text-primary mr-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showFormAssignDriver(record)}}},[_c('i',{staticClass:"fas fa-motorcycle"})]):_vm._e()]),_c('a-tooltip',{attrs:{"placement":"topLeft"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("Search near by driver")])]},proxy:true}],null,true)},[(record.pickup_status_id == 1)?_c('a',{staticClass:"text-primary mr-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showFormAssignBookingRequestDriver(
                                            record
                                        )}}},[_c('i',{staticClass:"fa fa-map-marker"})]):_vm._e()]),_c('a-tooltip',{attrs:{"placement":"topLeft"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("Cancel request")])]},proxy:true}],null,true)},[(
                                        record.pickup_status_id != 4 &&
                                        record.booking_pickups_count == 0
                                    )?_c('a',{staticClass:"text-primary mr-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showCancelRequest(record)}}},[_c('i',{staticClass:"fas fa-ban"})]):_vm._e()]),_c('a-tooltip',{attrs:{"placement":"topLeft"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("Confirm request and redirect to booking!")])]},proxy:true}],null,true)},[(
                                        record.alert_to_driver &&
                                        record.pickup_status_id == 1
                                    )?_c('a',{staticClass:"text-primary mr-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showConfirmRequest(record)}}},[_c('i',{staticClass:"fas fa-clipboard-check"})]):_vm._e()]),_c('a-tooltip',{attrs:{"placement":"topLeft"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("Create booking")])]},proxy:true}],null,true)},[(
                                        _vm.$can('create-booking-pickups') &&
                                        record.booking_pickups_count == 0 &&
                                        record.pickup_status_id == 2
                                    )?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                        _vm.$t('pickupRequest.createBooking')
                                    ),expression:"\n                                        $t('pickupRequest.createBooking')\n                                    "}],staticClass:"text-primary mr-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.createBooking(record)}}},[_c('i',{staticClass:"fas fa-cart-plus"})]):_vm._e()])],1)]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end tw-p-4"},[_c('ts-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1),_c('a-modal',{attrs:{"title":_vm.$t('pickupRequest.assignDriver'),"okText":_vm.$t('save'),"centered":true},on:{"ok":_vm.assignDriver},model:{value:(_vm.show_assign_driver),callback:function ($$v) {_vm.show_assign_driver=$$v},expression:"show_assign_driver"}},[_c('ts-loading-banner',{attrs:{"loading":_vm.fetch_driver}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 tw-space-y-2"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t('pickupRequest.driver')))]),_c('a-select',{staticClass:"tw-w-full",attrs:{"options":_vm.drivers,"showSearch":"","filterOption":function (input, option) { return option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0; }},model:{value:(_vm.model.driver_id),callback:function ($$v) {_vm.$set(_vm.model, "driver_id", $$v)},expression:"model.driver_id"}})],1)])])],1),_c('a-modal',{attrs:{"title":_vm.$t('pickupRequest.cancelPickupRequest'),"centered":true},on:{"ok":_vm.cancelRequest},model:{value:(_vm.show_cancel_request),callback:function ($$v) {_vm.show_cancel_request=$$v},expression:"show_cancel_request"}},[_c('ts-loading-banner',{attrs:{"loading":_vm.cancel_request_loading}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 tw-space-y-2"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t('pickupRequest.cancelReason')))]),_c('div',{staticClass:"tw-w-full tw-space-y-2"},[_c('a-select',{staticClass:"tw-w-full",attrs:{"options":_vm.cancelReason,"showSearch":"","filterOption":function (input, option) { return option.componentOptions.children[0].text
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0; }},model:{value:(_vm.cancel_reason),callback:function ($$v) {_vm.cancel_reason=$$v},expression:"cancel_reason"}}),(_vm.cancel_reason == 'ផ្សេងៗ')?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.cancel_reason),expression:"model.cancel_reason"}],staticClass:"form-control",attrs:{"rows":"4"},domProps:{"value":(_vm.model.cancel_reason)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "cancel_reason", $event.target.value)}}}):_vm._e()],1)])])])],1),(_vm.show_form)?_c('a-modal',{attrs:{"title":_vm.$t('pickupRequest.pageTitle'),"width":"400px","centered":true,"footer":null,"zIndex":1020},on:{"close":_vm.onClose},model:{value:(_vm.show_form),callback:function ($$v) {_vm.show_form=$$v},expression:"show_form"}},[_c('FormRequestPickup',{on:{"close":_vm.onClose}})],1):_vm._e(),_c('a-modal',{attrs:{"title":_vm.$t('pickupRequest.nearByDriver'),"width":"900px","centered":true,"footer":null,"zIndex":1020},on:{"cancel":_vm.onCloseShowBookingRequestDriver},model:{value:(_vm.show_booking_request_assign_driver),callback:function ($$v) {_vm.show_booking_request_assign_driver=$$v},expression:"show_booking_request_assign_driver"}},[_c('FormBookingRequestAssignDriver',{ref:"request_assign_driver",on:{"cancel":_vm.onCloseShowBookingRequestDriver},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }