<template>
    <ts-loading-banner :loading="fetch_data">
        <div class="tw-space-y-1">
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t('pickupRequest.shopName')
                    }}</label>
                    <a-select
                        show-search
                        v-model="model.shop_id"
                        style="width: 100%"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        :placeholder="$t('pickupRequest.typeSearch')"
                        @search="fetchShop"
                        :options="shop"
                        @change="onShopChange()"
                    ></a-select>
                    <div v-if="errors.has('shop_id')" class="tw-text-red-500">
                        {{ errors.first('shop_id') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label>{{ $t('pickupRequest.driverName') }}</label>
                    <a-select
                        show-search
                        v-model="model.driver_id"
                        style="width: 100%"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        :placeholder="$t('pickupRequest.typeSearch')"
                        @search="fetchDriver"
                        :options="drivers"
                    ></a-select>
                    <div v-if="errors.has('driver_id')" class="tw-text-red-500">
                        {{ errors.first('driver_id') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t('pickupRequest.serviceType')
                    }}</label>
                    <a-select
                        v-model="model.service_type_id"
                        class="tw-w-full"
                        :options="serviceType"
                    ></a-select>
                    <div
                        v-if="errors.has('service_type_id')"
                        class="tw-text-red-500"
                    >
                        {{ errors.first('service_type_id') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t('pickupRequest.packageType')
                    }}</label>
                    <a-select
                        v-model="model.package_type_id"
                        :options="packageType"
                        class="tw-w-full"
                    ></a-select>
                    <div
                        v-if="errors.has('package_type_id')"
                        class="tw-text-red-500"
                    >
                        {{ errors.first('package_type_id') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t('pickupRequest.vehicleType')
                    }}</label>
                    <a-select
                        v-model="model.vehicle_type_id"
                        class="tw-w-full"
                        :options="vehicleType"
                    ></a-select>
                    <div
                        v-if="errors.has('vehicle_type_id')"
                        class="tw-text-red-500"
                    >
                        {{ errors.first('vehicle_type_id') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label>{{ $t('pickupRequest.destination') }}</label>
                    <textarea rows="3" class="form-control"></textarea>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-md-12 tw-space-x-1 tw-flex tw-justify-end">
                    <ts-button @click.prevent="$emit('close')">{{
                        $t('cancel')
                    }}</ts-button>
                    <ts-button
                        color="primary"
                        @click.prevent="requestPickupForShop"
                        >{{ $t('request') }}</ts-button
                    >
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { debounce } from 'lodash'
export default {
    name: 'formRequestPickup',
    data () {
        return {
            errors: new Errors(),
            shop: [],
            drivers: [],
            packageType: [],
            serviceType: [],
            vehicleType: [],
            fetch_data: false,
            search_shop: undefined,
            model: {
                shop_id: undefined,
                service_type_id: null,
                vehicle_type_id: 1,
                package_type_id: 1,
                driver_id: undefined,
                destination: ''
            }
        }
    },
    created () {
        this.fetchData()
        this.clearInput()
    },
    methods: {
        fetchData () {
            this.fetch_data = true
            this.$store
                .dispatch('agency/requestPickup/getFormViewData', {
                    params: {
                        fnName: 'shop,packageType,serviceType,vehicleType,driver'
                    }
                })
                .then(resp => {
                    this.shop = resp.data.shop.map(el => ({
                        value: el.shop_id,
                        label: el.shop_name
                    }))
                    this.drivers = resp.data.driver.map(el => ({
                        value: el.driver_id,
                        label: el.driver_name
                    }))
                    this.packageType = resp.data.packageType.map(el => ({
                        value: el.package_type_id,
                        label: el.package_type_kh
                    }))
                    this.serviceType = resp.data.serviceType.map(el => ({
                        value: el.service_type_id,
                        label: el.service_type_name_kh
                    }))
                    this.vehicleType = resp.data.vehicleType.map(el => ({
                        value: el.vehicle_type_id,
                        label: el.vehicle_types
                    }))
                })
                .catch(error => {
                    this.$notify({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.fetch_data = false
                })
        },
        fetchShop: debounce(function (value) {
            this.fetch_data = true
            this.$store
                .dispatch('agency/requestPickup/getFormViewData', {
                    params: {
                        fnName: 'shop',
                        shop_search: value
                    }
                })
                .then(resp => {
                    this.shop = resp.data.shop.map(el => ({
                        value: el.shop_id,
                        label: el.shop_name
                    }))
                })
                .catch(error => {
                    this.$notify({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.fetch_data = false
                })
        }, 500),
        onShopChange () {
            this.fetch_data = true
            this.service_type_id = null
            this.$store
                .dispatch('agency/requestPickup/getFormViewData', {
                    params: {
                        fnName: 'serviceType',
                        shop_id: this.model.shop_id
                    }
                })
                .then(resp => {
                    this.serviceType = resp.data.serviceType.map(el => ({
                        value: el.service_type_id,
                        label: el.service_type_name_kh
                    }))
                })
                .catch(error => {
                    this.$notify({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.fetch_data = false
                })
        },
        fetchDriver: debounce(function (value) {
            this.fetch_data = true
            this.$store
                .dispatch('agency/requestPickup/getFormViewData', {
                    params: {
                        fnName: 'driver',
                        search_driver: value
                    }
                })
                .then(resp => {
                    this.drivers = resp.data.driver.map(el => ({
                        value: el.driver_id,
                        label: el.driver_name
                    }))
                })
                .catch(error => {
                    this.$notify({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.fetch_data = false
                })
        }, 500),
        requestPickupForShop () {
            this.fetch_data = true
            this.$store
                .dispatch(
                    'agency/requestPickup/requestPickupForShop',
                    this.model
                )
                .then(resp => {
                    this.$notify({ type: 'success', text: resp.message })
                    this.$emit('close')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.$notify({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.fetch_data = false
                })
        },
        clearInput () {
            this.model.shop_id = undefined
            this.model.service_type_id = null
            this.model.vehicle_type_id = 1
            this.model.package_type_id = 1
            this.model.destination = ''
        }
    }
}
</script>
