<template>
    <div>
        <ts-loading-banner :loading="loading">
            <div class="tw-flex tw-justify-between tw-items-center">
                <div class="tw-flex tw-space-x-3">
                    <div v-if="model.driver_id" class="tw-flex tw-space-x-3">
                        <a-button type="primary" @click.prevent="request">
                            {{ $t('requestForDelivery.request') }}
                            <i class="fas fa-bullhorn tw-ml-3"></i>
                        </a-button>
                    </div>
                    <a-button
                        type="primary"
                        @click.prevent="getNearBy"
                        v-if="!model.driver_id"
                    >
                        <i class="fas fa-search-location tw-mr-3"></i>
                        {{ $t('requestForDelivery.nearByDriver') }}
                    </a-button>
                    <div class="row tw-w-44">
                        <div class="col-md-12">
                            <a-select
                                show-search
                                v-model="model.driver_status_id"
                                style="width: 100%"
                                :default-active-first-option="false"
                                :show-arrow="false"
                                :filter-option="false"
                                :not-found-content="null"
                                :placeholder="$t('pickupRequest.driverStatus')"
                                :options="driverStatuses"
                            ></a-select>
                        </div>
                    </div>
                </div>
            </div>
            <div style="min-height: 400px">
                <table class="tw-w-full tw-mt-3">
                    <thead>
                        <tr>
                            <th
                                class="tw-uppercase tw-bg-gray-200 tw-py-1 tw-px-2"
                            >
                                {{ $t('requestForDelivery.driverCode') }}
                            </th>
                            <th
                                class="tw-uppercase tw-bg-gray-200 tw-py-1 tw-px-2"
                            >
                                {{ $t('requestForDelivery.driverName') }}
                            </th>
                            <th
                                class="tw-uppercase tw-bg-gray-200 tw-py-1 tw-px-2"
                            >
                                {{ $t('requestForDelivery.driverStatus') }}
                            </th>
                            <th
                                class="tw-uppercase tw-bg-gray-200 tw-py-1 tw-px-2"
                            >
                                {{ $t('requestForDelivery.driverPhone') }}
                            </th>
                            <th
                                class="tw-uppercase tw-bg-gray-200 tw-py-1 tw-px-2"
                            >
                                {{ $t('requestForDelivery.distance') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(d, index) in drivers"
                            :key="index"
                            class="tw-cursor-pointer hover:tw-bg-gray-300 tw-w-full tw-h-10"
                            :class="{
                                active: current_active == d.driver_id
                            }"
                            @click="selectDriver(d.driver_id)"
                        >
                            <td class="tw-py-1 tw-px-2">
                                {{ d.driver_code }}
                            </td>
                            <td class="tw-py-1 tw-px-2">
                                {{ d.driver_name }}
                            </td>
                            <td class="tw-py-1 tw-px-2">
                                {{ d.driver_status }}
                            </td>
                            <td class="tw-py-1 tw-px-2">
                                {{ d.phone1 + ' / ' + d.phone2 }}
                            </td>
                            <td>
                                {{
                                    round(d.distance / 1000, 2) +
                                    ' ' +
                                    $t('requestForDelivery.km')
                                }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ts-loading-banner>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { round } from 'lodash'
export default {
    props: ['value'],
    name: 'requestForDelivery',
    data () {
        return {
            loading: false,
            current_active: 0,
            drivers: [],
            driverStatuses: [],
            model: {}
        }
    },
    created () {
        this.model = this.value
        this.getNearBy()
        this.fetchViewData()
    },
    computed: {
        ...mapState('agency/requestPickup', ['formModels']),
        vehicleTypeList () {
            if (!this.formModels.vehicleType) return []

            return this.formModels.vehicleType.map(el => ({
                value: el.vehicle_type_id,
                label: el.vehicle_types
            }))
        }
    },
    methods: {
        ...mapActions('agency/requestPickup', [
            'getFormViewData',
            'nearByDrive',
            'requestDelivery'
        ]),
        round,
        async fetchViewData () {
            this.loading = true
            await this.getFormViewData({
                param: {
                    fnName: 'driverStatus'
                },
                index: 'driverStatus'
            }).then(resp => {
                this.driverStatuses = resp.data.driverStatus.map(el => ({
                    value: el.driver_status_id,
                    label: el.driver_status
                }))
            })
            this.loading = false
        },
        selectDriver (driver_id) {
            this.current_active = driver_id
            this.model.driver_id = driver_id
        },
        getNearBy () {
            this.loading = true
            this.nearByDrive({
                shop_id: this.model.shop_id,
                vehicle_type_id: this.model.vehicle_type_id,
                driver_status_id: this.model.driver_status_id
            })
                .then(response => {
                    this.drivers = response.data
                })
                .catch(error => {
                    this.$notify({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        request () {
            this.loading = true
            this.requestDelivery(this.model)
                .then(response => {
                    this.$notify({ type: 'success', text: response.message })
                })
                .catch(error => {
                    this.$notify({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.$emit('cancel')
                    this.loading = false
                })
        }
    }
}
</script>
<style lang="css" scoped>
.active {
    background: rgba(209, 213, 219);
}
</style>
